import {
  json,
  type LoaderFunction,
  type LoaderFunctionArgs,
} from '@remix-run/cloudflare';
import type {LinksFunction} from '@remix-run/server-runtime';
import {Outlet, useMatches} from '@remix-run/react';

import {preloadFont} from '@/utils/remix/preload-font';
import {Theme} from '@/enums';
import type {Handle, I18nRequestContext} from '@/types';
import Provider from '@/components/shared/Page/Provider';
import {getMainNav} from '@/components/brochureV2/section/Header/config/getMainNav.server';
import {getExperiment} from '@/utils/server/exp.server';
import {
  EXP_JAPAN_BROCHURE_DOWNLOAD,
  EXP_UPMARKET_CTA_ON_SHOPIFY_HOMEPAGE_APR24,
} from '@/experiments';

export const links: LinksFunction = () => {
  return [
    preloadFont('ShopifySans--regular.woff2'),
    preloadFont('ShopifySans--medium.woff2'),
    preloadFont('ShopifySans--bold.woff2'),
  ];
};
export const handle: Handle = {
  theme: Theme.BrochureV2,
  enableGtm: true,
};

export const loader: LoaderFunction = async (args: LoaderFunctionArgs) => {
  const {
    i18n: {loadNamespaces, t},
    page,
    site,
  } = args.context;

  await loadNamespaces('nav');

  const upMarketCtaVariation = getExperiment(
    args.request,
    EXP_UPMARKET_CTA_ON_SHOPIFY_HOMEPAGE_APR24,
    args.context,
  );
  const jpDownloadDocumentVariation = getExperiment(
    args.request,
    EXP_JAPAN_BROCHURE_DOWNLOAD,
    args.context,
  );

  const flags = {
    EXP_UPMARKET_CTA_ON_SHOPIFY_HOMEPAGE_APR24: upMarketCtaVariation,
    EXP_JAPAN_BROCHURE_DOWNLOAD: jpDownloadDocumentVariation,
  };

  const i18nRequestContext: I18nRequestContext = {
    t,
    page,
    site,
  };
  const mainNavData = getMainNav(i18nRequestContext, flags);

  return json({mainNavData});
};

export default function Layout() {
  const matches = useMatches();
  let merged = {};
  for (const match of matches) {
    if (match.data) {
      merged = {...merged, ...match.data};
    }
  }

  return (
    <Provider data={merged}>
      <Outlet />
    </Provider>
  );
}
